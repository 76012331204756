import type { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'

import { isFloat, isInt } from './validators'

type ContextMapItem = {
  name: string
  type?: string
}

const PrefillToContextMap: Record<string, ContextMapItem> = {
  'good.localisation.zipcode': {
    name: 'propertyZipcode',
    type: 'string',
  },
  'purchase.property_price': {
    name: 'propertyPrice',
  },
}

const matchPrefill = (key: string): RegExpMatchArray | null => key.match(/^prefill\[(.+)\]$/)

const getPrefillsFromEntries = (entries: [string, string][]): [string, string][] =>
  entries
    .map(([key, value]) => {
      const match = matchPrefill(key)
      if (match) {
        const [, field] = match
        return [field, value]
      }
      return null
    })
    .filter(Boolean) as [string, string][]

const mapType = (value: string, type?: string): string | number => {
  if (type === 'string') return value
  // Check !isNaN since it's a user input
  if (type === 'number' && !isNaN(parseInt(value))) return parseInt(value)
  // isInt and isFloat are regexp, no need for isNaN
  if (!type && isInt(value)) return parseInt(value)
  if (!type && isFloat(value)) return parseFloat(value)
  return value
}

const mapPrefills = (prefills: [string, string][]): [string, string | number][] =>
  prefills
    .map(([field, value]) => {
      const map = PrefillToContextMap[field]
      if (map) {
        const { name, type } = map
        return [name, mapType(value, type)]
      }
      return [field, mapType(value)]
    })
    .filter(Boolean) as [string, string | number][]

// Get Context from location
// /!\ This function is not pure (using window.location)
// Works with old method by using PrefillToContextMap
// Can also add any context value in prefill by using the new keys without any other config
export const getSentencesContextFromLocation = (): Partial<ProjectContext> => {
  const url = new URL(decodeURI(window.location.href))
  const search = new URLSearchParams(url.search)
  const entries: [string, string][] = Array.from(search.entries())
  const prefills = getPrefillsFromEntries(entries)
  const mappedPrefills = mapPrefills(prefills)

  const context = mappedPrefills.reduce((acc: Record<string, string | number>, [field, value]) => {
    if (field) acc[field] = value
    return acc
  }, {}) as Partial<ProjectContext>

  return context
}
