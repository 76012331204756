import { Choices } from '@pretto/app/src/Sentences/v2/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/v2/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField2'
import { YesNo } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'

export const BuyoutPage: React.FC = () => {
  const context = useSentences()
  const { buyoutAmount, buyoutCreditRemainingPrincipal, hasBuyoutCredit, setContext } = context

  const { activeField, isFilled, previousActiveField, setActiveField, refreshField } = useActiveField(
    ({ buyoutAmount, buyoutCreditRemainingPrincipal, hasBuyoutCredit }) => [
      isNil(buyoutAmount),
      isNil(hasBuyoutCredit),
      hasBuyoutCredit === true && isNil(buyoutCreditRemainingPrincipal),
    ]
  )

  const mapHasCreditValues = (values: YesNo[]) =>
    values.map(value => ({
      key: value,
      value,
      isSelected:
        (value === YesNo.Yes && hasBuyoutCredit === true) || (value === YesNo.No && hasBuyoutCredit === false),
      onClick: () => {
        setContext(({ buyoutCreditRemainingPrincipal }) => ({
          hasBuyoutCredit: value === YesNo.Yes,
          buyoutCreditRemainingPrincipal: value === YesNo.No ? null : buyoutCreditRemainingPrincipal,
        }))
        setActiveField(2)
      },
      label: t(`sentences.buyout.buyout.values.${value}`),
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'buyout' } }}>
      <Title>{t('sentences.buyout.title')}</Title>
      <div>
        {t('sentences.buyout.buyout.sentences.0')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'buyoutAmount' } }}
          inputProps={{ autoFocus: true, placeholder: '200 000', type: 'integer' }}
          onChange={(value: string) => {
            const buyoutAmount = value === '' ? null : parseInt(value)
            setContext({ buyoutAmount })
            refreshField({ buyoutAmount })
          }}
          value={buyoutAmount?.toString() || ''}
        />
        {t('sentences.buyout.buyout.sentences.1')}
      </div>
      {activeField >= 1 && (
        <div>
          {t('sentences.buyout.buyout.sentences.2')}
          <Prompt
            onClick={() => {
              setActiveField(1)
            }}
            isFilled={!isNil(hasBuyoutCredit)}
          >
            {t(`sentences.buyout.buyout.values.${hasBuyoutCredit === true ? 'yes' : 'no'}`)}
          </Prompt>
          {t('sentences.buyout.buyout.sentences.3')}
        </div>
      )}
      {activeField >= 2 && hasBuyoutCredit && (
        <div>
          {t('sentences.buyout.buyout.sentences.4')}
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'buyoutCreditRemainingPrincipal' },
            }}
            inputProps={{ autoFocus: previousActiveField === 1, placeholder: '200 000', type: 'integer' }}
            onChange={(value: string) => {
              setContext({ buyoutCreditRemainingPrincipal: value === '' ? null : parseInt(value) })
            }}
            value={buyoutCreditRemainingPrincipal?.toString() || ''}
          />
          {t('sentences.buyout.buyout.sentences.5')}
        </div>
      )}
      {activeField === 1 && (
        <Choices
          choices={mapHasCreditValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingAgeChildren' } }}
        />
      )}
      {isFilled && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
