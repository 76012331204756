import { g } from '@pretto/bricks/components/layout'

import { Message as MessageComponent, States } from '@pretto/zen/reveal/atoms/message/Message'

import styled from 'styled-components'

export const Message = styled(MessageComponent)`
  margin: ${g(2)} 0;
`

export const ErrorMessage = styled(Message).attrs({ state: States.Error })``

export const WarningMessage = styled(Message).attrs({ state: States.Warning })``
