import { g } from '@pretto/bricks/components/layout'

import { Buttons } from '@pretto/app/src/Sentences/v2/components/Styled/Buttons/Buttons'
import { ErrorMessage } from '@pretto/app/src/Sentences/v2/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/v2/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/v2/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/v2/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/v2/containers/ButtonNext/ButtonNext'
import { TrackedButtonChoice } from '@pretto/app/src/Sentences/v2/containers/TrackedButtonChoice/TrackedButtonChoice'
import { TrackedView } from '@pretto/app/src/Sentences/v2/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/v2/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/v2/contexts/SentencesContext'
import { createMortgagor } from '@pretto/app/src/Sentences/v2/lib/factories/mortgagor'
import { useActiveField } from '@pretto/app/src/Sentences/v2/lib/useActiveField'
import { ProjectContext } from '@pretto/app/src/Sentences/v2/types/context'
import { SimulationType, Structure } from '@pretto/app/src/Sentences/v2/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import styled from 'styled-components'

const Choices = styled(Buttons)`
  & > * + * {
    margin-left: ${g(1)};
  }
`

export const StructurePage: React.FC = () => {
  const context = useSentences()
  const { setContext, simulationType, structure, mortgagors } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && structure))
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}, {}]

  const handleClick = (type: Structure) => {
    const newContext: Partial<ProjectContext> = {
      structure: type,
    }
    if (type === Structure.Solo) {
      clonedMortgagors[1]._delete = true
      newContext.mortgagors = clonedMortgagors
    }
    if (type === Structure.Duo || type === Structure.SCI) {
      if (clonedMortgagors.length === 1) {
        clonedMortgagors.push(createMortgagor())
      } else {
        clonedMortgagors[1]._delete = false
      }
      newContext.mortgagors = clonedMortgagors
    }
    setContext(newContext)
    goToNextRoute()
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'structure' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t(
          `sentences.introduction.structure.sentences.${
            simulationType === SimulationType.Renegotiation ? 'renegotiation' : 'purchase'
          }.0`
        )}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!structure}>
          {t(`sentences.introduction.structure.values.${structure || 'solo'}`)}
        </Prompt>
        {t(
          `sentences.introduction.structure.sentences.${
            simulationType === SimulationType.Renegotiation ? 'renegotiation' : 'purchase'
          }.1`
        )}
      </div>
      {activeField === 0 && (
        <Choices>
          {Object.entries(
            simulationType === SimulationType.Renegotiation ? [Structure.Solo, Structure.Duo] : Structure
          ).map(([key, value]) => {
            return (
              <TrackedButtonChoice
                events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'structure', value } }}
                key={key}
                isSelected={structure === value}
                onClick={() => handleClick(value)}
              >
                {t(`sentences.introduction.structure.values.${value}`)}
              </TrackedButtonChoice>
            )
          })}
        </Choices>
      )}
      {simulationType === SimulationType.Buyout && structure && structure !== Structure.Solo && (
        <ErrorMessage>{t(`sentences.introduction.structure.error.nonSolo`)}</ErrorMessage>
      )}
      {((simulationType === SimulationType.Buyout && structure === Structure.Solo) ||
        (simulationType === SimulationType.Renegotiation &&
          (structure === Structure.Solo || structure === Structure.Duo)) ||
        simulationType === SimulationType.Capacity ||
        simulationType === SimulationType.Purchase) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
